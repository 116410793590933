<template>
  <v-container
    id="redirect"
    class="fill-height"
    tag="section"
    :style="$vuetify.breakpoint.mdAndUp ? 'width: 25%' : 'width: 75%'"
  >
    <div style="position: absolute; top: 10px; left: 20px">
      <img
        src="../../assets/logo.png"
        width="100"
        v-if="$vuetify.breakpoint.lgAndDown"
      />
      <img
        src="../../assets/logo.png"
        width="250"
        v-if="!$vuetify.breakpoint.lgAndDown"
      />
    </div>
    <v-row justify="center">
      <v-slide-y-transition appear>
        <div style="width: 30em">
          <v-row justify="center">
            <v-col cols="12">
              <h1>{{ studio_name }}</h1>
            </v-col>
            <v-col cols="12" v-if="links.inscription">
              <v-btn
                large
                block
                @click="
                  $router.push({
                    name: 'customer_inscription_studio',
                    params: { token: $route.params.token },
                  })
                "
              >
                <v-icon left>$request</v-icon> Pedir Cita
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              md="6"
              v-for="(link, i) in links.other_links"
              :key="i"
            >
              <v-btn large block elevation="0" :href="link.http">
                <v-icon left>mdi-open-in-new</v-icon> {{ link.name }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "REDIRECT",

  data: () => ({
    links: {},
    studio_name: null,
  }),

  mounted() {
    //console.log("entramos al redirect");
    this.fetchLinks();
  },

  methods: {
    ...mapActions("studios", ["redirectionLinks"]),

    fetchLinks() {
      this.redirectionLinks(this.$route.params.token).then((data) => {
        this.links = data.links;
        this.studio_name = data.studio;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
</style>
